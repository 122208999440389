html { 
  background: rgb(253, 245, 239);
}

.banner {
  height: 35vh;
  width: 76% ;
  margin: auto;
  -webkit-mask-image: linear-gradient(to top, transparent 5%, black 45%);
  mask-image: linear-gradient(to top, transparent 5%, black 35%);
}

.bannerMobile{
  height: 25vh;
  width: 100%;
  -webkit-mask-image: linear-gradient(to top, transparent 25%, black 75%);
  mask-image: linear-gradient(to top, transparent 25%, black 75%);
}

.header {
  font-family: 'Comfortaa-Reg';
  display: flex;
  align-items: flex;
  justify-content: left;
  padding: 16px;
}

.reporterInfo{
  background-color: #FFFFFF;
}

.propertyInfo{
  background-color: #FFFFFF;
}

.header img {
  height: 50px;
}

.header nav ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.header nav ul li {
  margin-right: 16px;
  margin-left: 16px;
  padding: 8px;
  border-radius: 20px;
  transition: background-color 0.3s ease-in-out
}

.header nav ul li:hover{
  background-color: #ffffff;
  color: #fff;
  border-radius: 20px;
  box-shadow: 0 0 10px #ffffff;
}

.header nav ul li a {
  text-decoration: none;
  color: #000000;
  font-weight: bold;
  display: inline-block;
  text-align: left;
}

.checkboxError {
  color: red;
  font-size: 12px;
  margin-left: 10px;
  margin-top: 0px;
  padding: 0px;
}

.submitbutton {
  background-color: #ff6600;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease-in-out;
}

.submitbutton:hover {
  background-color: #fff;
  color: #ff6600;
  box-shadow: 0 0 10px #ff6600;
}